'use client';

import ShopByCategoriesButton from '@/components/atoms/ShopByCategoriesButton';
import useAuth from '@/contexts/AuthProvider';
import AroggaAPI from '@/services/apis/AroggaAPI';
import { API_PUBLIC } from '@/services/apis/apiConstrant';
import { get, orderBy } from 'lodash';
import Link from 'next/link';
import { useEffect, useState } from 'react';
import { Extra, UploadPrescriptionLink } from './Extra';
import dynamic from 'next/dynamic';
import { usePathname } from 'next/navigation';
// import MegaMenu from './MegaMenu';

export default function Navbar({ menuData }) {
	const MegaMenu = dynamic(() => import('./MegaMenu'), { ssr: false });
	const { sideMenu, headerMenu } = menuData;
	const [loading, setLoading] = useState(false);
	const { menus, setMenus } = useAuth();
	const parantsMenu = get(sideMenu, 'data', [])?.filter((item: any) => item.mi_parent_mi_id === 0) || [];
	const pathname = usePathname()
	const shouldShowHeader = !pathname.startsWith('/maintenance');

	useEffect(() => {
		setLoading(true);
		const fetchMenus = async () => {
			try {
				const menuIds = parantsMenu.map((item) => item.id);
				const menuPromises = menuIds.map((id) =>
					AroggaAPI.get(API_PUBLIC.GET_MENUS, { _parent_id: id, _status: 1 })
				);
				const menuResponses = await Promise.all(menuPromises);
				const newMenus = menuResponses.flatMap((response) =>
					response.status === 'success' && Array.isArray(response.data) ? response.data : []
				);
				const updatedMenus = [...menus, ...newMenus].reduce((acc, current) => {
					const x = acc.find((item) => item.id === current.id);
					return x ? acc : [...acc, current];
				}, []);

				setMenus(updatedMenus);
			} catch (error) {
				console.error('Failed to fetch menu', error);
			} finally {
				setLoading(false);
			}
		};

		fetchMenus();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	if (!shouldShowHeader) {
		return null;
	}

	return (
		<div className={`header-navbar mr-5 `} id='header__bottom'>

			<div className='header_nav'>
				<ShopByCategoriesButton className='nav_category' />

				<div className='navbar__items' id='navbar__items'>
					<Link href={'/'}>
						<div id={'/'} className={'nav_item'}>
							<p className='navbar_text_size'>Home</p>
						</div>
					</Link>

					{orderBy(parantsMenu, 'mi_weight', 'asc').map((item: any, index: number) => {
						const link = item.mi_url || '/';
						const getLink = (link: string) => {
							switch (link) {
								case '/category/healthcare':
									return '/healthcare';
								case '/category/beauty':
									return '/beauty';
								default:
									return link || '/';
							}
						};

						return (
							<Link
								href={getLink(link)}
								key={index}
								onClick={(e) => {
									e.stopPropagation();
								}}>
								<div id={getLink(link)} className={'nav_item'}>
								<p className='navbar_text_size'>{item.mi_name}</p>
						
									<MegaMenu megaMenuData={item.children} id={item.id} />
								</div>
							</Link>
						);
					})}
					{orderBy(get(headerMenu, 'data', []), 'mi_weight', 'asc').map((item: any, index: number) => {
						if (item.mi_url === '/account/prescriptions') {
							return <UploadPrescriptionLink key={index} />;
						}
						return (
							<Link href={item.mi_url || '/'} key={index}>
								<div id={item.mi_url} className={'nav_item'}>
								<p className='navbar_text_size'>{item.mi_name}</p>
				
								</div>
							</Link>
						);
					})}
					<Link href={'/jobs'} className=''>
						<div id={'/jobs'} className={'nav_item'}>
							<div className='d-flex gap-5 items-center'>
								<div className='footer_links__link__g3_sK '> <p className='navbar_text_size'>Careers</p></div>
								<div className='arogga-btn primary small text-10 rounded-30 p-3 px-2'>
									<div className='inner  text-10'>We are hiring</div>
								</div>
							</div>
						</div>
					</Link>
				</div>
				<Extra hiddenClass={'nav_hide'} activeClass='nav__item__active' />
			</div>
		</div>


	);
}
